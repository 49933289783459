const filterStateDropdownByProfession = (professionId: number|null, stateDropdown: HTMLSelectElement) => {
    // if the value is empty, show all states
    if (null === professionId) {
        Array.from(stateDropdown.options).forEach((option) => {
            option.classList.remove('hidden');
        });
        return;
    }

    stateDropdown.disabled = true
    fetch(`/filter/state-by-profession/${professionId}`)
        .then(response => response.json())
        .then(data => {
            const stateIds = data.items;

            Array.from(stateDropdown.options).forEach((option) => {
                option.classList.remove('hidden');
                if (!stateIds.includes(parseInt(option.value)) && option.value !== "") {
                    option.classList.add('hidden');
                }
                setTimeout(() => {
                    stateDropdown.disabled = false
                }, 200);
            });
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

const filterProfessionDropdownByState = (stateId: number|null, professionDropdown: HTMLSelectElement) => {
    // if the value is empty, show all professions
    if (null === stateId) {
        Array.from(professionDropdown.options).forEach((option) => {
            option.classList.remove('hidden');
        });
        return;
    }

    professionDropdown.disabled = true
    fetch(`/filter/profession-by-state/${stateId}`)
        .then(response => response.json())
        .then(data => {
            const professionNameIds = data.items;

            Array.from(professionDropdown.options).forEach((option) => {
                option.classList.remove('hidden');
                if (!professionNameIds.includes(parseInt(option.value)) && option.value !== "") {
                    option.classList.add('hidden');
                }
                setTimeout(() => {
                    professionDropdown.disabled = false
                }, 200);
            });
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

const selectDefaultIfCurrentOptionIsHidden = (dropdown: HTMLSelectElement) => {
    if (dropdown.options[dropdown.selectedIndex].classList.contains('hidden')) {
        dropdown.selectedIndex = 0;
    }
}

/**
 * This will actively filter the profession and state dropdown based on the
 * selected state or profession.
 */
const register = () => {
    const searchBarForms: NodeListOf<HTMLFormElement> = document.querySelectorAll('form[name="product_search_simple"]');

    searchBarForms.forEach((searchBarForm) => {
        const professionDropdown: HTMLSelectElement = searchBarForm.querySelector('#product_search_simple_profession');
        const stateDropdown: HTMLSelectElement = searchBarForm.querySelector('#product_search_simple_state');

        if (!professionDropdown || !stateDropdown) {
            return;
        }

        // register events
        professionDropdown.addEventListener('change', () => {
            let value = professionDropdown.value === "" ? null : parseInt(professionDropdown.value);
            filterStateDropdownByProfession(value, stateDropdown);
            selectDefaultIfCurrentOptionIsHidden(stateDropdown);
        });

        stateDropdown.addEventListener('change', () => {
            let value = stateDropdown.value === "" ? null : parseInt(stateDropdown.value);
            filterProfessionDropdownByState(value, professionDropdown);
            selectDefaultIfCurrentOptionIsHidden(professionDropdown);
        });

        // also do it on page load
        let professionValue = professionDropdown.value === "" ? null : parseInt(professionDropdown.value);
        filterStateDropdownByProfession(professionValue, stateDropdown);
        selectDefaultIfCurrentOptionIsHidden(stateDropdown);
        let stateValue = stateDropdown.value === "" ? null : parseInt(stateDropdown.value);
        filterProfessionDropdownByState(stateValue, professionDropdown);
        selectDefaultIfCurrentOptionIsHidden(professionDropdown);
    });
}

export {register}
