import "@local/css/tailwind.css";
import "@local/sass/main.scss";
import { register as registerMenus } from "@local/ts/interactions/MenuInteractions";

import GLightbox from "glightbox";
window.GLightbox = GLightbox;

import { domReady, domSelectorReady } from "@local/ts/lib/Utility";
import * as IMask from "@local/ts/interactions/InputMaskInteractions";
import * as DeltaScroll from "@local/ts/interactions/DeltaScrollInteractions";
import * as ElementToggleInteractions from "@local/ts/interactions/ElementToggleInteractions";
import * as CarouselInteractions from "@local/ts/interactions/CarouselInteractions"
window["ElementToggleInteractions"] = ElementToggleInteractions;
import * as GLightboxInteractions from "@local/ts/interactions/GLightboxInteractions";
import * as StateProfessionDropdownInteractions from "@local/ts/interactions/StateProfessionDropdownInteractions";
import * as CartInteractions from '@local/ts/interactions/CartInteractions';
import * as FilterFormSubmitInteractions from '@local/ts/interactions/FilterFormSubmitInteractions';
import * as SearchStateProfessionDropdownInteractions from '@local/ts/interactions/SearchStateProfessionDropdownInteractions';
import { svelteDOM } from "@local/ts/lib/svelte-dom-loader";
import { reactDOM } from "@local/ts/lib/react-dom-loader";

// collapsible svelte component
import Accordion from "@local/svelte/FaqAccordion.svelte";
svelteDOM.registerComponents([
  {
    component: Accordion,
    name: "Accordion",
    props: { name: "typescript" },
  },
]);

(async () => {
  await domReady();
  registerMenus();
  GLightboxInteractions.register();
  IMask.mask();
  DeltaScroll.register({
    dead_zone:50,
    minimum_movement:30,
    below_threshold:60,
    small_width:1024,
    small_height:915
  });
  ElementToggleInteractions.register();
  CarouselInteractions.register();
  await StateProfessionDropdownInteractions.register();
  CartInteractions.register();
  FilterFormSubmitInteractions.register();
  SearchStateProfessionDropdownInteractions.register();

  const body = await domSelectorReady("body");
  body[0].classList.remove("disable-animations");
})();

console.log("App loaded");
