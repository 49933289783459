type Action = "add" | "remove";
type LinkStyles = "add" | "remove" | "processing";

interface Product {
    id: number;
    fqcn: string;
}

interface ItemFromCartContents {
    id: number;
    entityFQCN: string;
}

/**
 * Defines the multiple states of cart action links.
 */
const defaultLinkValues = {
    "add": { // when the item is not in the cart
        iconHTML: `<svg style="pointer-events: none;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plus"><path d="M5 12h14"/><path d="M12 5v14"/></svg>`,
        label: "Add to cart",
        classNames: [],
        enabled: true,
        opacity: 1,
    },
    "remove": { // when the item is in the cart
        iconHTML: `<svg style="pointer-events: none;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-shopping-cart"><circle cx="8" cy="21" r="1"/><circle cx="19" cy="21" r="1"/><path d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"/></svg>`,
        label: "Remove from cart",
        classNames: ["!bg-transparent","!text-red-500","!shadow-none","hover:!text-red-300"],
        enabled: true,
        opacity: 1,
    },
    "processing": { // when the cart is processing an action
        iconHTML: `<svg style="pointer-events: none;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-loader-circle animate-spin"><path d="M21 12a9 9 0 1 1-6.219-8.56"/></svg>`,
        label: "",
        classNames: ["!bg-transparent","!text-slate-300","!shadow-none"],
        enabled: false,
        opacity: 1,
    },
}

/**
 * Handles the cart action event. This is usually triggered by a click event on
 * a cart action link.
 * @param link
 * @param action
 * @param items
 */
const handleActionEvent = (link: HTMLElement, action: Action, items: Product[]): void => {
    configureLink(link, "processing" as LinkStyles);
    const nextAction = action === "add" ? "remove" : "add";

    fetch(`/cart/${action}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            items: items,
        }),
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error("Failed to add item to cart");
            }
            return response.json();
        })
        .then((data) => {
            // added or removed
            setTimeout(() => {
                updateCartCountIndicator(data.cart.itemCount);
                configureLink(link, nextAction);
            }, 200);
        })
        .catch((error) => {
            console.error("Failed to add item to cart", error);
            configureLink(link, action);
        });
}

/**
 * This function configures the link with the appropriate styles and attributes
 * based on the configured defaultLinkValues above.
 * @param link
 * @param style
 */
const configureLink = (link: HTMLElement, style: LinkStyles): void => {
    const escapedFQCN = link.dataset.cartEntityFqcn?.replace(/\\/g, "\\\\");
    const selectorText = `[data-cart-entity-id="${link.dataset.cartEntityId}"][data-cart-entity-fqcn="${escapedFQCN}"]`;
    const allMatchingLinks = document.querySelectorAll(selectorText) as NodeListOf<HTMLElement>;

    allMatchingLinks.forEach((link: HTMLElement) => {
        const defaultValues = defaultLinkValues[style];
        link.innerHTML = `${defaultValues.iconHTML} <span style="pointer-events: none;">${defaultValues.label}</span>`;
        link.dataset.cartAction = style;

        link.classList.remove(...Object.values(defaultLinkValues).map((value) => value.classNames).flat());
        if (defaultValues.classNames.length > 0) {
            link.classList.add(...defaultValues.classNames);
        }

        if (defaultValues.enabled) {
            link.dataset.allowClick = "true";
        } else {
            link.dataset.allowClick = "false";
        }

        // handle opacity with an important flag
        link.style.opacity = defaultValues.opacity.toString();
    });
}

/**
 * This function extracts the action, entityId, and entityFQCN from the link
 * element's attributes.
 * @param link
 */
const getLinkValues = (link: HTMLElement): { action: string; entityId: number; entityFQCN: string } => {
    const action = link.dataset.cartAction || "";
    const entityId = parseInt(link.dataset.cartEntityId || "");
    const entityFQCN = link.dataset.cartEntityFqcn || "";
    return {action, entityId, entityFQCN};
}

/**
 * Updates the cart count indicator with the provided count. If the count is
 * null, it will attempt to get the count from the existing indicator
 * textContent.
 * @param count
 */
const updateCartCountIndicator = (count: number | null): void => {
    const cartCountIndicator = document.querySelector("[data-cart-count]") as HTMLElement;

    if (count === null) {
        // get the count from the existing indicator textContent
        count = parseInt(cartCountIndicator.textContent || "0");
    }

    if (cartCountIndicator) {
        cartCountIndicator.innerHTML = count.toString();

        if (count > 0) {
            cartCountIndicator.classList.add('!bg-primary')
        } else {
            cartCountIndicator.classList.remove('!bg-primary')
        }
    }
}

/**
 * Registers the cart link actions. This function will iterate over the provided
 * cartLinks and configure the link with the appropriate styles and attributes.
 * @param cartLinks
 */
const registerCartLinkActions = (cartLinks: NodeListOf<HTMLElement>) => {
    cartLinks.forEach((link) => {
        let {action} = getLinkValues(link);

        const isActionValid = action === "add" || action === "remove";
        if (isActionValid) {
            configureLink(link, action as Action);
        }

        link.addEventListener("click", (event) => {
            event.preventDefault();
            event.stopPropagation();

            if (link.dataset.allowClick === "false") {
                return;
            }

            let {action, entityId, entityFQCN} = getLinkValues(link);

            if (entityId === 0 || entityFQCN === "") {
                console.error("Cart action link missing data attributes", link, action, entityId, entityFQCN);
                return;
            }

            const isActionValid = action === "add" || action === "remove";
            if (!isActionValid) {
                console.error("Cart action link has invalid action", link, action);
                return;
            }

            let items: Product[] = [];
            items.push({id: entityId, fqcn: entityFQCN});
            handleActionEvent(link, action as Action, items);
        });
    });
}

/**
 * This function will dynamically update cart buttons and the cart count in
 * the event we have hit back/forward in the browser history. This is necessary
 * because browser history pulls from cache and does not re-render the page with
 * the latest data.
 */
const loadInitialLinkStates = (cartLinks: NodeListOf<HTMLElement>) => {

    cartLinks.forEach((link) => {
        configureLink(link, "processing");
    });

    fetch("/cart/content", {
        method: "GET",
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error("Failed to get cart content");
            }
            return response.json();
        })
        .then((data) => {
            updateCartCountIndicator(data.cart.itemCount);
            cartLinks.forEach((link) => {
                let {entityId, entityFQCN} = getLinkValues(link);
                const isItemInCart = data.cart.items.find((item: ItemFromCartContents) => item.entityFQCN === entityFQCN && item.id === entityId);
                configureLink(link, isItemInCart ? "remove" : "add");
            })
        })
        .catch((error) => {
            console.error("Failed to get cart content", error);
        });
}

const register = (): void => {
    updateCartCountIndicator(0);
    const cartLinks = document.querySelectorAll("[data-cart-action]") as NodeListOf<HTMLElement>;

    // handle browser back/forward button navigation
    window.addEventListener("pageshow", function (event) {
        let historyTraversal = event.persisted ||
            (typeof window.performance != "undefined" &&
                window.performance.navigation.type === 2);
        loadInitialLinkStates(cartLinks);
        registerCartLinkActions(cartLinks);
    });
}

export {register};
