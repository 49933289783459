const registry_attribute = "data-toggles-enabled";
const supported_attributes = [
    "data-closes",
    "data-toggles",
    "data-opens",
    "data-opens-modal",
    "data-closes-modal",
    "data-toggles-modal",
];
const $body = document.querySelector("body");

/**
 * handles opening behavior for elements targeted by `data-opens`
 * @param selector
 */
const handleOpen = (selector: string) => {
    const $el = document.querySelector(selector) as HTMLElement;
    if ($el) {
        $el.dataset.toggled = "true";
        updateBodyToggledPanels(selector, true);
        $el.classList.remove("hidden");
    }
};

/**
 * handles closing behavior for elements targeted by `data-closes`
 * @param selector
 */
const handleClose = (selector: string) => {
    const $el = document.querySelector(selector) as HTMLElement;
    if ($el) {
        $el.dataset.toggled = "false";
        updateBodyToggledPanels(selector, false);
        $el.classList.add("hidden");
    }
};

/**
 * handles toggling behavior for elements targeted by `data-toggles`
 * @param selector
 */
const handleToggle = (selector: string) => {
    const $el = document.querySelector(selector) as HTMLElement;
    if ($el) {
        const toggled = elementIsToggled($el);
        $el.dataset.toggled = toggled ? "false" : "true";
        updateBodyToggledPanels(selector, !toggled);
        $el.classList.toggle("hidden");
    }
};

const handleOpenModal = (selector: string) => {
    const $el = document.querySelector(selector) as HTMLDialogElement;
    if ($el) {
        $el.showModal();
    }
};

const handleCloseModal = (selector: string) => {
    const $el = document.querySelector(selector) as HTMLDialogElement;
    if ($el) {
        $el.close();
    }
};

const handleToggleModal = (selector: string) => {
    const $el = document.querySelector(selector) as HTMLDialogElement;
    if ($el) {
        $el.open ? $el.close() : $el.showModal();
    }
};

/**
 * checks if an element is toggled
 * @param $el
 */
const elementIsToggled = ($el: HTMLElement) => {
    return $el.dataset.toggled === "true";
};

/**
 * updates the body's data-toggled-panels attribute by adding or removing the
 * selector from the list of toggled panels
 * @param selector
 * @param toggled
 */
const updateBodyToggledPanels = (selector: string, toggled: boolean) => {
    const toggled_panels_csv = $body.getAttribute("data-toggled-panels");
    const toggled_panels = toggled_panels_csv
        ? toggled_panels_csv.split(",")
        : [];

    toggled
        ? toggled_panels.push(selector)
        : toggled_panels.splice(toggled_panels.indexOf(selector), 1);

    $body.setAttribute("data-toggled-panels", toggled_panels.join(","));
};

/**
 * checks if an element is registered based on the registry_attribute
 * @param $el
 */
const elementIsRegistered = ($el: HTMLElement) => {
    return $el.hasAttribute(registry_attribute);
};

/**
 * register an element using the registry_attribute
 * @param $el
 */
const registerElement = ($el: HTMLElement) => {
    $el.setAttribute(registry_attribute, "true");
};

/**
 * adds a click listener to an element that processes actions based on the
 * elements data attributes
 * @param $el
 */
const addClickListener = ($el: HTMLElement) => {
    $el.addEventListener("click", () => {
        supported_attributes.forEach((attr) => {
            const target_selector = $el.getAttribute(attr);
            if (target_selector) {
                switch (attr) {
                    case "data-toggles":
                        handleToggle(target_selector);
                        break;
                    case "data-opens":
                        handleOpen(target_selector);
                        break;
                    case "data-closes":
                        handleClose(target_selector);
                        break;
                    case "data-toggles-modal":
                        handleToggleModal(target_selector);
                        break;
                    case "data-opens-modal":
                        handleOpenModal(target_selector);
                        break;
                    case "data-closes-modal":
                        handleCloseModal(target_selector);
                        break;
                }
            }
        });
    });
};

/**
 * registers all elements that have a supported data-attribute. Registered
 * elements receive click behavior that can toggle the state of other elements.
 * Available attributes include "data-toggles", "data-opens", "data-closes"
 */
const register = () => {
    const selector = supported_attributes.map((attr) => `[${attr}]`).join(",");
    document.querySelectorAll(selector).forEach(($el: HTMLElement) => {
        if (elementIsRegistered($el)) return;
        registerElement($el);
        addClickListener($el);
    });
};

export { register };
