interface CarouselOptions {
    container: string;
    items: string;
    prevButton: string;
    nextButton: string;
    indicatorContainer: string;
    autoplay?: boolean; // Whether to autoplay
    autoplayDelay?: number; // Delay in milliseconds
}

class TestimonialCarousel {
    private currentPage: number;
    private itemsPerPage: number;
    private items: HTMLElement[];
    private indicators: HTMLElement[];
    private container: HTMLElement;
    private itemsContainer: HTMLElement;
    private totalPages: number;
    private autoplayTimer?: number;
    private observer?: IntersectionObserver;

    constructor(private options: CarouselOptions) {
        this.container = document.querySelector(options.container) as HTMLElement;
        this.itemsContainer = this.container.querySelector('.carousel-items') as HTMLElement;
        this.items = Array.from(this.itemsContainer.querySelectorAll(options.items)) as HTMLElement[];
        this.currentPage = 0;

        // Dynamically determine itemsPerPage based on viewport size
        if (window.innerWidth >= 1024) {
            // Large devices (lg)
            this.itemsPerPage = 3;
        } else if (window.innerWidth >= 768) {
            // Medium devices (md)
            this.itemsPerPage = 2;
        } else {
            // Small devices (base styles)
            this.itemsPerPage = 1;
        }

        this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
        // console.log(this.itemsPerPage);
        // console.log(this.totalPages);

        this.initialize();
    }

    private initialize() {
        this.setupIndicators();
        this.addEventListeners();
        this.updateTransform();

        // Initialize autoplay if enabled
        if (this.options.autoplay) {
            this.setupIntersectionObserver();
        }
    }

    private setupIntersectionObserver() {
        const options = {
            root: null, // Uses the viewport as the root
            threshold: 0.1 // Trigger when 10% of the carousel is visible
        };

        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Start autoplay when carousel is in view
                    this.startAutoplay();
                } else {
                    // Stop autoplay when carousel is out of view
                    this.stopAutoplay();
                }
            });
        }, options);

        this.observer.observe(this.container);
    }

    private startAutoplay() {
        // Clear any existing timer
        this.stopAutoplay();

        // Start new timer
        this.autoplayTimer = window.setInterval(() => {
            this.next(true);
        }, this.options.autoplayDelay || 5000);
    }

    private stopAutoplay() {
        if (this.autoplayTimer) {
            window.clearInterval(this.autoplayTimer);
            this.autoplayTimer = undefined;
        }
    }

    private resetAutoplay() {
        if (this.options.autoplay && this.autoplayTimer) {
            this.startAutoplay();
        }
    }

    private updateTransform() {
        const containerWidth = this.container.offsetWidth;
        const translateX = this.currentPage * containerWidth;
        this.itemsContainer.style.transform = `translateX(-${translateX}px)`;
        this.updateIndicators();
    }

    private setupIndicators() {
        const indicatorContainer = document.querySelector(this.options.indicatorContainer) as HTMLElement;
        if (!indicatorContainer) return;

        this.indicators = Array.from(indicatorContainer.querySelectorAll("[data-carousel-indicator]")) as HTMLElement[];

        this.indicators.forEach((indicator, index) => {
            indicator.dataset.index = index.toString();
            indicator.addEventListener("click", () => {
                this.goToPage(index);
                this.resetAutoplay();
            });
        });

        this.updateIndicators();
    }

    private updateIndicators() {
        const viewportWidth = window.innerWidth;
        let maxVisibleIndicators: number;

        // Adjust the number of indicators based on breakpoints
        if (viewportWidth >= 1024) {
            maxVisibleIndicators = Math.min(3, Math.floor(this.indicators.length / 3)); // Large devices
        } else if (viewportWidth >= 768) {
            maxVisibleIndicators = Math.min(3, Math.floor(this.indicators.length / 2)); // Medium devices
        } else {
            maxVisibleIndicators = Math.min(3, this.indicators.length); // Smallest devices
        }

        // console.log(viewportWidth, maxVisibleIndicators);

        const totalIndicators = this.indicators.length;
        const halfVisible = Math.floor(maxVisibleIndicators / 2);

        this.indicators.forEach((indicator, index) => {
            const isActive = index === this.currentPage;

            // Calculate visibility range
            const isVisible =
                index >= Math.max(0, this.currentPage - halfVisible) &&
                index <= Math.min(totalIndicators - 1, this.currentPage + halfVisible);

            // Set visibility
            indicator.style.display = isVisible ? "inline-block" : "none";

            // Update active state
            indicator.setAttribute("data-active", isActive ? "true" : "false");
            indicator.classList.toggle("active", isActive);
            indicator.classList.toggle("bg-secondary", isActive);
            indicator.classList.toggle("scale-125", isActive);
        });
    }

    private addEventListeners() {
        const prevButton = document.querySelector(this.options.prevButton) as HTMLElement;
        const nextButton = document.querySelector(this.options.nextButton) as HTMLElement;

        prevButton?.addEventListener("click", () => {
            this.prev();
            this.resetAutoplay();
        });
        nextButton?.addEventListener("click", () => {
            this.next();
            this.resetAutoplay();
        });

        // Handle window resize to update the transform
        window.addEventListener('resize', () => this.updateTransform());
    }

    private prev() {
        if (this.currentPage > 0) {
            this.currentPage -= 1;
        } else {
            // Loop back to the last page
            this.currentPage = this.totalPages - 1;
        }
        this.updateTransform();
    }

    private next(fromAutoplay = false) {
        if (this.currentPage < this.totalPages - 1) {
            this.currentPage += 1;
        } else {
            // Loop back to the first page
            this.currentPage = 0;
        }
        this.updateTransform();

        if (!fromAutoplay) {
            this.resetAutoplay();
        }
    }

    private goToPage(pageIndex: number) {
        if (pageIndex >= 0 && pageIndex < this.totalPages) {
            this.currentPage = pageIndex;
            this.updateTransform();
        }
    }
}

// Register carousel behavior based on data attributes
const register = (): void => {
    const carouselContainers = document.querySelectorAll("[data-carousel-container]") as NodeListOf<HTMLElement>;

    carouselContainers.forEach((container) => {
        const options: CarouselOptions = {
            container: container.dataset.carouselContainer || "",
            items: container.dataset.carouselItems || "",
            prevButton: container.dataset.carouselPrev || "",
            nextButton: container.dataset.carouselNext || "",
            indicatorContainer: container.dataset.carouselIndicators || "",
            autoplay: container.dataset.carouselAutoplay === "true",
            autoplayDelay: parseInt(container.dataset.carouselAutoplayDelay || "5000"),
        };

        new TestimonialCarousel(options);
    });
};

export { register };