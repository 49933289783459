const getSelectedStateIDAndName = (stateDropdown: HTMLSelectElement) => {
    let selectedStateId = stateDropdown.value;
    let selectedStateName = stateDropdown.options[stateDropdown.selectedIndex].text;

    return { selectedStateId, selectedStateName };
}

const updateProfessionsFromStateChoice = async (stateDropdown: HTMLSelectElement, professionDropdown: HTMLSelectElement, licenseNumberInput: HTMLInputElement) => {
    // professionDropdown.classList.add('hidden');
    // professionDropdown.setAttribute('disabled', 'disabled');
    // professionDropdown.innerHTML = '<option value="">Loading...</option>';
    // licenseNumberInput.value = '';
    // licenseNumberInput.classList.add('hidden');
    // licenseNumberInput.setAttribute('disabled', 'disabled');

    // log the selected state name and id
    let {selectedStateId, selectedStateName} = getSelectedStateIDAndName(stateDropdown);

    if (!selectedStateId) {
        professionDropdown.innerHTML = '<option value="">Select a state first</option>';
        professionDropdown.setAttribute('disabled', 'disabled');
        professionDropdown.classList.add('hidden');
        licenseNumberInput.value = '';
        licenseNumberInput.setAttribute('disabled', 'disabled');
        licenseNumberInput.classList.add('hidden');
        return;
    }

    let response = await fetch(`/async/professions/byStateId/${selectedStateId}`);
    let validProfessions = await response.json();

    let options = validProfessions.map((profession: any) => {
        if (profession.groupLabel) {
            let groupHTML = `<optgroup label="${profession.groupLabel}">`;
            for (let option of profession.children) {
                groupHTML += `<option value="${option.id}" data-show-license-input="${option.showLicenseInput}" data-help-text="${option.helpText}" ${option.disabled ? 'disabled selected' : ''}>${option.name}</option>`;
            }
            groupHTML += '</optgroup>';

            if (profession.children.length === 0) {
                groupHTML = '';
            }
            return groupHTML;
        }

        return `<option value="${profession.id}" data-show-license-input="${profession.showLicenseInput}" data-help-text="${profession.helpText}" ${profession.disabled ? 'disabled selected' : ''}>${profession.name}</option>`;
    });

    // licenseNumberInput.classList.remove('hidden');
    // licenseNumberInput.removeAttribute('disabled');

    professionDropdown.innerHTML = options.join('');
    professionDropdown.removeAttribute('disabled');
    professionDropdown.classList.remove('hidden');

    updateHelpTextAndLicenseField(professionDropdown, licenseNumberInput);
}

const updateHelpTextAndLicenseField = (professionDropdown: HTMLSelectElement, licenseNumberInput: HTMLInputElement) => {
    let selectedProfessionHelpText = professionDropdown.options[professionDropdown.selectedIndex].getAttribute('data-help-text');
    let showLicenseInput = professionDropdown.options[professionDropdown.selectedIndex].getAttribute('data-show-license-input');
    const licenseHelpText = licenseNumberInput.nextElementSibling;

    if (showLicenseInput === 'true') {
        licenseNumberInput.required = true;
        licenseNumberInput.classList.remove('hidden');
        licenseNumberInput.removeAttribute('disabled');
    } else {
        licenseNumberInput.required = false;
        licenseNumberInput.classList.add('hidden');
        licenseNumberInput.setAttribute('disabled', 'disabled');
    }
    licenseHelpText.classList.add('text-sm');
    licenseHelpText.innerHTML = selectedProfessionHelpText;
}

const register = async () => {
    const formsWithStateProfessionDropdown = document.querySelectorAll('form:has([data-profession-dropdown])');

    // @ts-ignore
    for (const form of formsWithStateProfessionDropdown) {
        form.reset(); // fixes an issue in firefox where selected="selected" doesn't work on initial load
        const stateDropdown: HTMLSelectElement = form.querySelector('[data-state-dropdown]');
        const professionDropdown: HTMLSelectElement = form.querySelector('[data-profession-dropdown]');
        const licenseNumberInput: HTMLInputElement = form.querySelector('[data-license-number-input]');

        if (stateDropdown.value) {
            stateDropdown.setAttribute('disabled', 'disabled');
        }
        if (professionDropdown.value) {
            professionDropdown.setAttribute('disabled', 'disabled');
        } else {
            professionDropdown.classList.add('hidden');
        }

        if (licenseNumberInput.value) {
            licenseNumberInput.removeAttribute('disabled');
        } else {
            licenseNumberInput.classList.add('hidden');
        }

        if (!professionDropdown.value || !licenseNumberInput.value) {
            await updateProfessionsFromStateChoice(stateDropdown, professionDropdown, licenseNumberInput);
        }

        stateDropdown.addEventListener('change', async () => {
            await updateProfessionsFromStateChoice(stateDropdown, professionDropdown, licenseNumberInput);
        });

        professionDropdown.addEventListener('change', () => {
            updateHelpTextAndLicenseField(professionDropdown, licenseNumberInput);
        });
        form.reset(); // fixes an issue in firefox where selected="selected" doesn't work on initial load
    }
}

export { register }